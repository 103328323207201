// design
import { getMDColorClass } from "@/design/colors/Color";

// vuex/store
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("solution");

/**
 * progress Settings Mixin encapsulate progress Settings functionality
 */
export const progressSettingsMixin = {
  computed: {
    ...mapGetters({
      progressColor: "progressColor"
    }),
    /**
     * Get progress Color Class
     * @returns {string|*}
     */
    progressColorClass() {
      return getMDColorClass(
        this.progressColor.name,
        this.progressColor.variantType,
        this.progressColor.variantNumber
      );
    }
  }
};
