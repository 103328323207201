<template>
  <v-container fluid>
    <v-row class="fill-height" align-content="center" justify="center">
      <v-col :class="titleClass" cols="12">
        <slot>Working ...</slot>
      </v-col>
      <v-col cols="8">
        <v-progress-linear
          :color="progressColorClass"
          :height="progressHeight"
          indeterminate
          rounded
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// design
import {
  displayTypes,
  fontEmphasis,
  alignments,
  Text
} from "@/design/text/Text";

// mixins
import { progressSettingsMixin } from "@/mixins/shared/base/settings/progressSettingsMixin";

const getTitleClass = () => {
  const txt = new Text(undefined, displayTypes.subtitle1, fontEmphasis.regular);
  txt.alignment = alignments.center;
  return txt.getClassText();
};

export default {
  name: "ProgressDisplay",
  mixins: [progressSettingsMixin],
  props: {
    progressHeight: {
      type: Number,
      default: 6
    },
    titleClass: {
      type: String,
      default: getTitleClass()
    }
  }
};
</script>
